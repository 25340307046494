@import './common.css';
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* About Page Styles */
.about-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  min-height: 100vh; /* Ensures the content takes the full height */
  width: 100%; /* Takes the full width of the viewport */
}

.about-content {
  width: 100%;
  max-width: 600px;
  background-color: white;
  padding: 40px 20px 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center; /* Centers the text within the container */
  position: relative;
}

.go-back-link {
  position: absolute;
  top: 10px;
  left: 10px;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  font-size: 1.2rem;
}

.about-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.about-description {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 20px;
}


.about-subtitle {
  font-weight: bold; /* Makes the text bold */
}
