.logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0; /* Top and bottom padding */
    width: 100%; /* Full width to ensure centering */
}

.logo {
    width: 150px; /* Fixed width for the logo */
    height: auto; /* Maintain aspect ratio */
    margin-bottom: 10px;
    transition: margin-bottom 0.5s ease; /* Smooth transition for any margin changes */
}

/* Ensure all main containers are flex and center aligned */
.main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh; /* Full viewport height */
}

.black-icon {
    color: black; 
}