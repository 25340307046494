@import './common.css';
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.feedback-form-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    min-height: 100vh; /* Ensures the content takes the full height */
    width: 100%; /* Takes the full width of the viewport */
}

.feedback-content {
    width: 100%;
    max-width: 600px;
    background-color: white;
    padding: 40px 20px 20px;
    border-radius: 10px;
    text-align: center; /* Centers the text within the container */
    position: relative;
    border: 1px solid #ddd;
}

.go-back-link {
    position: absolute;
    top: 10px;
    left: 10px;
    color: blue;
    text-decoration: underline;
    cursor: pointer;
    font-size: 1.2rem;
}

.feedback-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
}

form div {
    margin-bottom: 20px;
}

label {
    display: block;
    margin-bottom: 8px;
}

input, textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
}

button {
    background-color: white;
    color: black;
    padding: 10px 20px; /* Adjust padding if needed */
    border: 2px solid black; /* Add a border for the black overlay effect */
    border-radius: 4px;
    cursor: pointer;
    font-size: 1.25rem; /* Increase font size */
    font-weight: bold; /* Make the text bold */
    transition: background-color 0.3s ease, color 0.3s ease; /* Add transition for smooth color change */
    width: 100%; /* Make the button width 100% */
    box-sizing: border-box; /* Ensure padding and border are included in the width calculation */
}

button:hover {
    background-color: black; /* Change background to black on hover */
    color: white; /* Change text color to white on hover */
}

.error {
    color: red;
    font-size: 0.875rem;
}

/* Custom styles for toast messages */
.toast-container .Toastify__toast {
    background-color: white; /* White background color */
    color: black; /* Black text color */
    border-radius: 8px; /* Rounded corners */
    font-size: 0.780rem; /* Smaller font size */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow for a nice effect */
    border-left: 5px solid black; /* Black line on the left */
    padding: 10px 15px; /* Padding to make it more compact */
    position: relative;
    overflow: hidden; /* To ensure the overlay doesn't push content */
}

.toast-container .Toastify__toast::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 100%;
    background-color: black; /* Black line on the left */
}

.toast-container .Toastify__toast--success {
    border-left-color: black; /* Ensure the black line for success messages */
}

.toast-container .Toastify__toast--error {
    border-left-color: red; /* Red line for error messages */
}

.toast-container .Toastify__toast-body {
    position: relative;
    z-index: 1;
}

.toast-container .Toastify__progress-bar {
    background: black; /* Progress bar color */
}

.toast-container .Toastify__toast-icon svg {
    fill: black; /* Change the color of the checkmark icon to black */
}