@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


/* General styles for icon buttons and other elements */
.icon-button {
  position: relative; /* Ensure tooltip is positioned correctly */
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
  
}
.icon-button:hover {
  transform: translateY(-10px);
  box-shadow: none;
  background-color: transparent; 
}
.icon-button:active {
  transform: translateY(-5px);
  box-shadow: none;
}
.bg-lightgreen {
  background-color: #e8f5e9;  
}
.recommendation-container {
  max-width: 600px; /* Shrink the suggestion result modal */
  margin: 0 auto;
  text-align: left;
}
/* Tooltip container */
.tooltip {
  position: absolute;
  bottom: 100%; /* Position above the icon */
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  color: white;
  padding: 5px;
  border-radius: 5px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease;
}
.icon-button:hover .tooltip,
.icon-button.selected .tooltip {
  opacity: 1;
  visibility: visible;
}
/* Logo styling */
.logo {
  width: 150px; /* Fixed width for the logo */
  height: auto;
  margin-bottom: 10px; /* Reduce gap between logo and buttons */
  transition: margin-bottom 0.5s ease;
  transform: translateX(-27px); 
}
/* Ensure the suggestion container can scroll when content is too long */
.suggestion-container {
  padding: 20px;
  margin-top: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #fff; /* Change this line */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px; /* Shrink the suggestion result modal */
  text-align: left;
  line-height: 1.6;
  white-space: pre-line;
  animation: fadeIn 1s;
  overflow-y: auto; /* Allow vertical scrolling */
  max-height: 30vh; /* Set a maximum height */
}


/* Ensure the suggestion text doesn't overflow the container */
.suggestion-text {
  font-size: 1rem;
  color: #555;
  word-break: break-word; /* Ensure long words break to fit the container */
}

.feedback-message {
  font-size: 1rem;
  color: #000; /* Black color for feedback message */
  margin-top: 10px;
}

.next-action-message {
  font-size: 1rem;
  color: #000; /* Black color for next action message */
  margin-top: 20px;
}

.flex-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between; /* Add this line to space content between top and bottom */
  width: 100%;
  padding: 20px 20px; /* Adjust padding as needed */
  box-sizing: border-box;
  transition: padding-top 0.5s ease;
  position: relative;
}

.app-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the content horizontally */
  width: 100%;
  margin-top: -20px; /* Move buttons up closer to the logo */
  margin-bottom: 20px; /* Add margin-bottom to reduce gap */
}

.flex-container.suggestion-active .logo {
  display: none; /* Hide logo when a suggestion is active */
}

.shake {
  animation: shake 0.5s;
}

/* Animation styles */
.spin-3d {
  animation: spin 0.5s ease-in-out;
}
@keyframes spin {
  0% { transform: rotateY(0deg); }
  50% { transform: rotateY(180deg); }
  100% { transform: rotateY(360deg); }
}
.pulse {
  animation: pulse 1s infinite;
}


@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}
.fadeIn {
  animation: fadeIn 1s ease-in-out;
}
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
.shake:hover {
  animation: shake 0.5s;
}
@keyframes shake {
  0%, 100% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  75% { transform: translateX(5px); }
}
.glow {
  animation: glow 1s infinite alternate;
}
@keyframes glow {
    from {
      box-shadow: 0 0 5px #ff6666;
    }
    to {
      box-shadow: 0 0 20px #ff6666, 0 0 30px #ff6666, 0 0 40px #ffcccc, 0 0 50px #ffcccc, 0 0 60px #ffcccc;
    }
  }
  

/* Styles for share buttons */
/* Styles for share buttons */
.react-share__ShareButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
}
.react-share__ShareButton:hover {
  transform: translateY(-10px);
  box-shadow: none !important; /* Remove box-shadow */
  background-color: transparent !important; /* Remove background color */
}
.react-share__ShareButton:active {
  transform: translateY(-5px);
  box-shadow: none !important; /* Remove box-shadow */
}


.footer {
  width: 100%;
  height: auto;
  position: relative;
  bottom: 0;
}

.footer-links {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.footer-links li {
  display: inline;
}

.footer-links a {
  color: #000;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}

/* Custom styles for toast messages */
.toast-container .Toastify__toast {
  background-color: white; /* White background color */
  color: black; /* Black text color */
  border-radius: 8px; /* Rounded corners */
  font-size: 0.875rem; /* Smaller font size */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow for a nice effect */
  border-left: 5px solid black; /* Black line on the left */
  padding: 10px 15px; /* Padding to make it more compact */
  position: relative;
  overflow: hidden; /* To ensure the overlay doesn't push content */
}

.toast-container .Toastify__toast::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 5px;
  height: 100%;
  background-color: black; /* Black line on the left */
}

.toast-container .Toastify__toast--success {
  border-left-color: black; /* Ensure the black line for success messages */
}

.toast-container .Toastify__toast--error {
  border-left-color: red; /* Red line for error messages */
}

.toast-container .Toastify__toast-body {
  position: relative;
  z-index: 1;
}

.toast-container .Toastify__progress-bar {
  background: black; /* Progress bar color */
}

/* Custom styles for the toast icon */
.toast-container .Toastify__toast-icon svg {
  fill: black; /* Change the color of the checkmark icon to black */
}
